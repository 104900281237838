import React, { useState, useEffect } from "react";
import { Pane } from "evergreen-ui";
import { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";

import { NavBar } from "./components"
import Routes from "./Routes";


function App(props) {

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {

    async function onLoad() {
      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
      }
      catch(e) {
        if (e !== 'No current user') {
          console.log(JSON.stringify(e));
        }
        else { 
          var path = props.history.location.pathname;
          if (path !== '/signup' && path !== '/terms')
            props.history.push("/login");
        }
      }
      setIsAuthenticating(false);
    }

    onLoad();
  }, [props.history]);
  

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    props.history.push("/login");
  } 

  return (
    !isAuthenticating &&
    <Pane>
      {isAuthenticated && props.history.location.pathname !== '/terms' ? <NavBar logoutHandler={handleLogout} /> : <></>}
      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
    </Pane>
  )
};

export default withRouter(App);
