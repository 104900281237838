import React, { useState, useEffect } from "react";
import { Dialog,Paragraph,TextInputField,Link } from "evergreen-ui";

import { API } from "aws-amplify";


export default function TwilioDetails(props) {
    const [accountSid, setAccountSid] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText,setErrorText] = useState(false);

    useEffect(() => {
        if (accountSid.length === 34 && authToken.length === 32)
            setConfirmDisabled(false)
    },[accountSid,authToken]);

    async function handleSubmit(event) {
        setIsLoading(true);
        setConfirmDisabled(false);
        
        try {
            await updateAccountSid(accountSid, authToken);
            setIsLoading(false);
            setConfirmDisabled(false);
            setAccountSid("");
            setAuthToken("");
            props.updateAccountSid(accountSid);
            props.closeDialog()
        } catch (e) {
            setErrorText('Oops! An error occurred. ' + e.message)
            setIsLoading(false);
        }
    }


    function updateAccountSid(acct,auth) {
        return API.post("admin", "/twilio/authorize", {
            body: {account_sid:acct, auth_token:auth}
        });
    }

    return (
        
        <Dialog
            isShown={props.showDialog}
            title="Authenticate with Twilio"
            confirmLabel="Update"
            isConfirmDisabled={confirmDisabled}
            isConfirmLoading={isLoading}
            onCloseComplete={props.closeDialog}
            onConfirm={() => { handleSubmit() }}
        >
            <Paragraph>In order to begin capturing your data from Twilio, we need your Account SID and Auth Token.</Paragraph>
            <br/>
            <Paragraph>Your auth token is stored securely, segregated, and always encrypted in-transit and at rest. Learn more about how we store your credentials <Link href="#">here.</Link></Paragraph>
            <br/>
            <TextInputField label="Master Account SID" id="accountSid" value={accountSid} onChange={e => setAccountSid(e.target.value)} placeholder="ACxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" />
            <br/>
            <TextInputField label="Master Auth Token" id="authToken" value={authToken} onChange={e => setAuthToken(e.target.value)} placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" />
            <br/>
            <Paragraph color="red">{errorText}</Paragraph>
        </Dialog>
    );
}