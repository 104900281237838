import React from "react";
import { Pane,Heading } from "evergreen-ui";

export default function NotFound() {
  return (
    <Pane marginTop={20}>
      <Heading size={800}>Sorry, page not found!</Heading>
    </Pane>
  );
}
