import React, { useState, useEffect } from "react";
import { Dialog,Paragraph,TextInputField } from "evergreen-ui";

import { API } from "aws-amplify";


export default function AddUserDialog(props) {
    const [confirmText, setConfirmText] = useState("");
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText,setErrorText] = useState(false);

    useEffect(() => {
        if (confirmText === 'DELETE')
            setConfirmDisabled(false)
    },[confirmText]);

    async function handleSubmit(event) {
        setIsLoading(true);
        setConfirmDisabled(false);
        
        try {
            await deleteUser(props.username);
            setIsLoading(false);
            setConfirmDisabled(false);
            setConfirmText("");
            props.closeDialog()
        } catch (e) {
            setErrorText('Oops! An error occurred. ' + e.message)
            setIsLoading(false);
        }
    }


    function deleteUser(u) {
        return API.post("admin", "/reporting-user/delete", {
            body: {username:u}
        });
    }

    return (
        
        <Dialog
            isShown={props.showDialog}
            title="Delete user"
            intent="danger"
            confirmLabel="Delete"
            isConfirmDisabled={confirmDisabled}
            isConfirmLoading={isLoading}
            onCloseComplete={props.closeDialog}
            onConfirm={() => { handleSubmit() }}
        >
            <Paragraph>Confirm and you will permanently delete this user's access to the Envocate analytics system. </Paragraph>
            <br/>
            <TextInputField label="Type DELETE" id="confirmText" type="text" value={confirmText} onChange={e => setConfirmText(e.target.value)} />
            <br/>
            <Paragraph color="red">{errorText}</Paragraph>
        </Dialog>
    );
}