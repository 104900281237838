import React, { useState,useEffect } from "react";
import { Dialog,Paragraph,TextInputField } from "evergreen-ui";

import { API } from "aws-amplify";


export default function AddUserDialog(props) {
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText,setErrorText] = useState(false);


    useEffect(() => {
        if (email.length > 0)
            setConfirmDisabled(false)
    },[email,phoneNumber]);

    async function handleSubmit(event) {
        setIsLoading(true);
        setConfirmDisabled(false);
        
        try {
            await addUser(email,phoneNumber);
            setIsLoading(false);
            setConfirmDisabled(false);
            setEmail("");
            setPhoneNumber("");
            props.closeDialog()
        } catch (e) {
            setErrorText(e.response.data.Message)
            setIsLoading(false);
        }
    }


    function addUser(em,ph) {
        return API.post("admin", "/reporting-user", {
            body: {email:em,phone_number:ph}
        });
    }

    return (
        
        <Dialog
            isShown={props.showDialog}
            title="Add/invite user"
            onConfirm={() => {  handleSubmit()}}
            confirmLabel="Add user"
            onCloseComplete={props.closeDialog}
            isConfirmDisabled={confirmDisabled}
            isConfirmLoading={isLoading}
        >
            <Paragraph>Enter the user's email address, and an invitation with a temporary password will be sent to their email address. This will allow them to enter the Envocate reporting and analytics system. </Paragraph>
            <br/>
            <TextInputField label="Email" id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
            <TextInputField label="Phone Number" id="phone-number" type="phone-number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
            <br/>
            <Paragraph color="red">{errorText}</Paragraph>
        </Dialog>
    );
}