import React, { useState } from "react";
import { TextInputField,Pane,Button,Link,Paragraph } from "evergreen-ui";
import { Auth } from "aws-amplify";

export default function Signup(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorText,setErrorText] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");

  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      email.length > 0 &&
      password.length > 0 &&
      password === confirmPassword
    );
  }

  function validateConfirmationForm() {
    return confirmationCode.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
  
    setErrorText("");
    setIsLoading(true);
  
    try {
      const newUser = await Auth.signUp({
        username: email,
        password: password
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      setErrorText(e.message);
      setIsLoading(false);
    }
  }
  
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
  
    setErrorText("");
    setIsLoading(true);
  
    try {
      await Auth.confirmSignUp(email, confirmationCode);
      await Auth.signIn(email, password);
  
      props.userHasAuthenticated(true);
      props.history.push("/");
    } catch (e) {
      setErrorText(e.message);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
        <Pane clearfix justifyContent="center" alignItems="center">
        <Pane elevation={4} width={600} marginRight="auto" marginLeft="auto" marginTop={24} padding={40} justifyContent="left" alignItems="left" flexDirection="column">
          <Link href='/'><img src="logo-white.png" alt="envocate" style={{height:50+'px'}} /></Link>
          <br/>
          <Paragraph marginTop={10} marginBottom={10}>Enter the confirmation code you received via email.</Paragraph><br/>
          <form  onSubmit={handleConfirmationSubmit}>
            <TextInputField label="Confirmation Code" id="confirmationCode" type="tel" value={confirmationCode} onChange={e => setConfirmationCode(e.target.value)} />
            <Button marginRight={16} appearance="primary" intent="none" disabled={!validateConfirmationForm() || isLoading} type="submit">Confirm</Button>
          </form>
            <br/>
          <Paragraph color="red">{errorText}</Paragraph>
        </Pane>
      </Pane>
    )};


  function renderForm() {
    return (
      <Pane clearfix justifyContent="center" alignItems="center">
        <Pane elevation={4} width={600} marginRight="auto" marginLeft="auto" marginTop={24} padding={40} justifyContent="left" alignItems="left" flexDirection="column">
          <Link href='/'><img src="logo-white.png" alt="envocate" style={{height:50+'px'}} /></Link>
          <br/>
          <Paragraph marginTop={10} marginBottom={10}>Welcome to Envocate! Signup to get started. You'll be placed into a 14-day free trial on our developer plan ($300 per month after 14 days).<br/><br/> To provision your account, you will also need to Connect to Twilio, and enter a credit card. By signing up, you explicitly agree to the <Link href="terms" target="blank">terms</Link>.</Paragraph><br/>
          <form  onSubmit={handleSubmit}>
            <TextInputField label="Email" id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
            <TextInputField label="Password" id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
            <TextInputField label="Confirm Password" id="confirmPassword" type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
          
            <Button marginRight={16} appearance="primary" intent="none" disabled={!validateForm() || isLoading} type="submit">Sign Up</Button>
            &nbsp;&nbsp;<Link href="login">Login instead</Link>
            </form>
            <br/>
          <Paragraph color="red">{errorText}</Paragraph>
        </Pane>
      </Pane>
    )};


  return (
    <Pane>
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </Pane>
  );

  }
