import React, { useState, useEffect } from "react";
import { Dialog,Paragraph,TextInputField } from "evergreen-ui";

import { API } from "aws-amplify";


export default function ConfirmPlan(props) {
    const [confirmText, setConfirmText] = useState("");
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText,setErrorText] = useState(false);

    useEffect(() => {
        if (confirmText === 'CONFIRM')
            setConfirmDisabled(false)
    },[confirmText]);

    async function handleSubmit(event) {
        setIsLoading(true);
        setConfirmDisabled(false);
        
        try {
            await updatePlan(props.plan);
            setIsLoading(false);
            setConfirmDisabled(false);
            setConfirmText("");
            props.updateplan(props.plan);
            props.closeDialog()
        } catch (e) {
            setErrorText('Oops! An error occurred. ' + e.message)
            setIsLoading(false);
        }
    }


    function updatePlan(p) {
        return API.post("admin", "/account/update-plan", {
            body: {plan:p}
        });
    }

    return (
        
        <Dialog
            isShown={props.showDialog}
            title="Change Plan"
            intent="danger"
            confirmLabel="Confirm"
            isConfirmDisabled={confirmDisabled}
            isConfirmLoading={isLoading}
            onCloseComplete={props.closeDialog}
            onConfirm={() => { handleSubmit() }}
        >
            <Paragraph>By confirming, you will update your plan subscription to {props.plan}.  </Paragraph>
            <br/>
            <Paragraph>This will update your reporting instance. Your system will experience some downtime between 2-6 AM, but no data will be lost. </Paragraph>
            <br/>
            <TextInputField label="Type CONFIRM" id="confirmText" type="text" value={confirmText} onChange={e => setConfirmText(e.target.value)} />
            <br/>
            <Paragraph color="red">{errorText}</Paragraph>
        </Dialog>
    );
}