import React, { useState, useEffect} from "react";
import { Pane,Heading,Link,Paragraph } from "evergreen-ui";
import { API } from "aws-amplify";
import qs from "qs";

export default function TwilioConnect(props) {
    const [heading, setHeading] = useState(true);
    const [text, setText] = useState(true);

    useEffect(() => {
        async function onLoad() {
            if (!props.isAuthenticated) {
              return;
            }

            setHeading("Hold up...");
            setText("We're connecting with your Twilio account and getting everything set up.")

            try {
                var accountSid = qs.parse(props.location.search.substr(1));
                await connectToTwilio(accountSid);
            }
            catch (err) {
                setHeading("Oops...");
                setText("We failed. We have been alerted to our failure however, and will reach out soon.")

            }

            setHeading("Success.");
            setText("You are good to go. You should start receiving events soon! Click the logo to head on back home.")


        }

        onLoad();

      }, [props.isAuthenticated,props.location.search]);

      function connectToTwilio(accountSid) {
        return API.post("admin", "/twilio/authorize", {
            body: accountSid
          });
      }

    return (
        <Pane clearfix justifyContent="center" alignItems="center">
            <Pane
                elevation={4}
                width={600}
                marginRight="auto"
                marginLeft="auto"
                marginTop={24}
                padding={40}
                justifyContent="left"
                alignItems="left"
                flexDirection="column"
            >
                <Link href='/'><img src="logo-white.png" alt="envocate" style={{height:50+'px'}} /></Link>
                <br/>
                <Heading size={700} marginTop={10}>{heading}</Heading>
                <Paragraph marginTop={24} marginBottom={10}>{text}</Paragraph><br/>
            </Pane>
        </Pane>
    );
}
