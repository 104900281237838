import React, { useState, useEffect } from "react";
import { Dialog,Paragraph,TextInputField,Textarea,Label } from "evergreen-ui";

import { API } from "aws-amplify";


export default function EnterpriseRequest(props) {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [text, setText] = useState("");
    const [confirmDisabled, setConfirmDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [errorText,setErrorText] = useState(false);

    useEffect(() => {
        if (phoneNumber.length === 12 && text.length > 0)
            setConfirmDisabled(false)
    },[text,phoneNumber]);

    async function handleSubmit(event) {
        setIsLoading(true);
        setConfirmDisabled(false);
        
        try {
            await updatePlan(props.plan, phoneNumber, text);
            setIsLoading(false);
            setConfirmDisabled(false);
            setText("");
            setPhoneNumber("");
            props.closeDialog()
        } catch (e) {
            setErrorText('Oops! An error occurred. ' + e.message)
            setIsLoading(false);
        }
    }


    function updatePlan(p,ph,t) {
        return API.post("admin", "/account/update-plan", {
            body: {plan:p, phone_number:ph, text:t}
        });
    }

    return (
        
        <Dialog
            isShown={props.showDialog}
            title="Request to join enterprise"
            intent="danger"
            confirmLabel="Send request"
            isConfirmDisabled={confirmDisabled}
            isConfirmLoading={isLoading}
            onCloseComplete={props.closeDialog}
            onConfirm={() => { handleSubmit() }}
        >
            <Paragraph>Our enterprise plan is customized to your needs. We include guaranteed encryption in transit and at rest, multi-node redundancy for 100% uptime, services for augmenting and enhancing your platform and we will sign a BAA for firms looking for HIPAA compliance. </Paragraph>
            <br/>
            <Paragraph>Fill out the form below and a member of our team will reach out. </Paragraph>
            <br/>
            <TextInputField label="Contact phone #" id="phoneNumber" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} placeholder="+11234567890" />
            <br/>
            <Label htmlFor="text" marginBotton={4} display="block">Describe your needs</Label>
            <Textarea id="text" value={text} onChange={e => setText(e.target.value)} />
            <br/>
            <Paragraph color="red">{errorText}</Paragraph>
        </Dialog>
    );
}