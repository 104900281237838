import React from "react";
import { Button, Icon, Menu, Pane, Popover, Position, Link } from "evergreen-ui";
import { colors, styles } from "../styles/globals";

export default function NavBar(props) {

  return (
    
    <Pane alignItems="center" elevation={1} display="flex" height={75} paddingRight={styles.gutter}>
        <Pane flex={1} display="flex">
            <Link href='/'><img src="logo-white.png" alt="envocate" style={{height:50+'px'}} /></Link>
        </Pane>
        <Pane>
            <Popover position={Position.BOTTOM_RIGHT} content={<HamburgerMenu logoutHandler={props.logoutHandler} />}> 
            <Button appearance="minimal" padding={0}>
                <Icon color={colors.black} icon="menu" size={24} />
            </Button>
            </Popover> 
        </Pane>
    </Pane>
  );
}

function HamburgerMenu(props) {
  return (
    <Menu>
      <Pane backgroundColor={colors.offWhite}>
        <Menu.Group title="">
          <Menu.Item><Link href="terms" target="blank" style={{textDecoration:'none'}} color="neutral">Terms and Conditions</Link></Menu.Item>
          <Menu.Item onSelect= {props.logoutHandler}>
              Logout
          </Menu.Item>
        </Menu.Group>
      </Pane>
    </Menu>
  );
}
