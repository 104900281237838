import React, { useEffect,useState } from "react";
import { Pane, Heading, Table, Link, Text, Paragraph, Tooltip, Spinner, SegmentedControl, toaster } from "evergreen-ui";
import { API } from "aws-amplify";
import { CardDialog, ConfirmPlan, EnterpriseRequest, TwilioDetails } from "../components"

export default function StatusBox(props) {
    const [status, setStatus] = useState("");
    const [kibanaUri, setkibanaUri] = useState("");
    const [accountSID, setAccountSID] = useState("");
    const [plan, setPlan] = useState("");
    const [planToUpdate, setPlanToUpdate] = useState("");
    const [paymentdigits, setPaymentdigits] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage,setErrorMessage] = useState("");
    const [showPaymentDialog,setShowPaymentDialog] = useState(false);
    const [hasAccountSID, setHasAccountSID] = useState(false);
    const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
    const [showConfirmPlan, setShowConfirmPlan] = useState(false);
    const [showEnterprisePlan, setShowEnterprisePlan] = useState(false);
    const [showTwilioDetails, setShowTwilioDetails] = useState(false);
    

    const planOptions = [
      { label: 'Developer', value: 'developer' },
      { label: 'Pro', value: 'pro' },
      { label: 'Enterprise', value: 'enterprise' },
    ]

    useEffect(() => {
        async function onLoad() {
      
          try {
            const accountDetails = await getAccountDetails();
            
            setStatus(accountDetails.status);
            setkibanaUri(accountDetails.kibanaUri);
            setAccountSID(accountDetails.accountSid);
            setPaymentdigits(accountDetails.last4);
            setPlan(accountDetails.plan);

            setHasAccountSID(typeof accountDetails.accountSid !== 'undefined')
            setHasPaymentMethod(accountDetails.payment_status === 'current')

            if (typeof accountDetails.accountSid === 'undefined' && accountDetails.status==='provisioned')
                toaster.danger(
                    'No Twilio Account SID',
                    {
                        description: 'In order to get started, you need to connect Envocate to your Twilio account. Use the Connect button in the left hand pane.'
                    }
                )
            if (accountDetails.payment_status !== 'current')
                toaster.danger(
                    'No payment method',
                    {
                        description: 'Envocate requires a valid payment method to get started. Remember, you have a 14 day free trial.'
                    }
                )
            if (accountDetails.status === 'waiting to provision')
                toaster.notify(
                    'Provision in progress',
                    {
                        description: 'Your reporting environment is provisioning. When completed, your status will be updated and your URI will be available. Please refresh the page in 5 minutes.'
                    }
                )
            
          } catch (e) {
            setErrorMessage("Uh oh. Either you don't have a connection to the internet, or there is evil afoot!")
          }
          setIsLoading(false)
        }
      
        onLoad();

      }, []);

    function getAccountDetails() {
        return API.get("admin", "/account");
    };
    
    function renderAccountSid() {
        return (
            <>
                {accountSID}&nbsp;
                <Link href="#" onClick={() => setShowTwilioDetails(true) }>Update</Link>
            </>
        )
    }

    function renderTwilioButton() {
        // eslint-disable-next-line
        return (
            <>
                <a href="https://www.twilio.com/authorize/CN55ba853663685f1e7ff11da53dcaef80" id="twilio-connect-button"> </a>
            </>
        )
    }

    function closeDialogs() {
        setShowPaymentDialog(false);
        setShowConfirmPlan(false);
        setShowEnterprisePlan(false);
        setShowTwilioDetails(false);
    }


    function renderComponentBody () {
        return(
            <Pane border="muted"  padding={16}>
                <Pane>
                    <Heading size={700}>Status</Heading><br/>
                    <Paragraph>This shows the status of your deployment and allows you to change your plan and billing parameters. </Paragraph><br/>
                </Pane>
                <Pane>
                    <Table>
                        <Table.Body>
                            <Table.Row>
                                <Table.TextCell flexBasis="25%" flexShrink={0} flexGrow={0}>
                                    <Tooltip content="This is your currently enrolled plan/service tier.">
                                        <Text size={300}>Plan</Text>
                                    </Tooltip>
                                    </Table.TextCell>
                                <Table.TextCell textAlign="right">
                                    <SegmentedControl marginLeft="auto" height={25} width={240} options={planOptions} value={plan} onChange={value => { setPlanToUpdate(value); if (value==='enterprise') setShowEnterprisePlan(true); else setShowConfirmPlan(true); }} /> </Table.TextCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.TextCell flexBasis="25%" flexShrink={0} flexGrow={0}>
                                    <Tooltip content="This is the status of your Envocate analytics service deployment. Envocate deployment size is based on your plan. After you enroll or change plans, the status will likely say 'provisioning'. This means your Envocate services are warming up. The system will be inaccessible for a short time at the Analytics URI.">
                                        <Text size={300}>Deployment Status</Text>
                                    </Tooltip>
                                </Table.TextCell>
                                <Table.TextCell textAlign="right">{status}</Table.TextCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.TextCell flexBasis="25%" flexShrink={0} flexGrow={0}>
                                    <Tooltip content="This is the Master Account SID from Twilio that is linked with your Envocate account. Envocate polls Twilio Studio to extract logging information frequently. It reads from all subaccounts of the Master account. Envocate can today only reference one Master account.">
                                        <Text size={300}>Account SID</Text>
                                    </Tooltip></Table.TextCell>
                                <Table.TextCell textAlign="right">{status=='provisioned' ? renderAccountSid() : <Text>To set up your Twilio Account SID, you must be provisioned.</Text> }</Table.TextCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.TextCell flexBasis="25%" flexShrink={0} flexGrow={0}>
                                    <Tooltip content="This is the administration console, for service provisioning, user authentication and billing. Users of Envocate will access this URL, which is where their installation and reports will reside.">
                                        <Text size={300}>Analytics URI</Text>
                                    </Tooltip></Table.TextCell>
                                <Table.TextCell textAlign="right">
                                    {status==='provisioned' ? <Link target="_blank" href={kibanaUri}>Available</Link> : "Not Available"}
                                </Table.TextCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.TextCell flexBasis="25%" flexShrink={0} flexGrow={0}>
                                    <Tooltip content="You must pay.">
                                        <Text size={300}>Payment Method</Text>
                                    </Tooltip></Table.TextCell>
                                <Table.TextCell textAlign="right">{hasPaymentMethod ? 
                                    <>card ending in {paymentdigits} <Link href="#" onClick={() => setShowPaymentDialog(true) }>Change</Link></> : 
                                    <Link href="#" onClick={() => setShowPaymentDialog(true) }>Add Payment Method</Link> }
                                </Table.TextCell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Pane>
                <br/>
                <Paragraph color="red">{errorMessage}</Paragraph>

               <CardDialog showDialog={showPaymentDialog} closeDialog={closeDialogs} updateLast4={setPaymentdigits}  updateHasPaymentMethod={setHasPaymentMethod} updateLoading={setIsLoading} />
               <ConfirmPlan showDialog={showConfirmPlan} closeDialog={closeDialogs} updateplan={setPlan} updateLoading={setIsLoading} plan={planToUpdate} />
               <EnterpriseRequest showDialog={showEnterprisePlan} closeDialog={closeDialogs} updateplan={setPlan} updateLoading={setIsLoading} plan={planToUpdate} />
               <TwilioDetails showDialog={showTwilioDetails} closeDialog={closeDialogs} updateAccountSid={setAccountSID} updateLoading={setIsLoading} />
            </Pane>
        )
    }

    return (
        <>{ !isLoading ? renderComponentBody() : <Spinner marginX="auto" marginY={120} />}</>
    );

}