export const colors = {
    backgroundBlue: "rgb(11, 19, 53)",
    green: "rgb(84, 187, 76)",
    offWhite: "rgb(249, 249, 251)",
    white: "rgb(255, 255, 255)"
  };
  
  export const styles = {
    gutter: 20
  };
  