import React, { useState } from "react";
import { TextInputField,Pane,Button,Heading,Link,Paragraph } from "evergreen-ui";
import { Auth } from "aws-amplify";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
  
    try {
      await Auth.signIn(email, password);
      props.userHasAuthenticated(true);
      props.history.push("/");
    } catch (e) {
      setValidationMessage(e.message);
      setIsLoading(false);
    }
  }
  

  return (

  <Pane clearfix justifyContent="center" alignItems="center">
    <Pane
    elevation={4}
    width={600}
    marginRight="auto"
    marginLeft="auto"
    marginTop={24}
    padding={40}
    justifyContent="left"
    alignItems="left"
    flexDirection="column"
    >
      <Link href='/'><img src="logo-white.png" alt="envocate" style={{height:50+'px'}} /></Link>
      <br/>
      <Heading size={700} marginTop={10}>Login</Heading>
      <Paragraph marginTop={24} marginBottom={10}>Welcome to Envocate. Login to manage your account.</Paragraph><br/>
      <form  onSubmit={handleSubmit}>
        <TextInputField label="Email" id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
        <TextInputField label="Password" id="password" type="password" value={password} onChange={e => setPassword(e.target.value)}/>
      
        <Button marginRight={16} appearance="primary" intent="none" disabled={!validateForm() || isLoading} type="submit">Submit</Button>
        &nbsp;&nbsp;<Link href="signup">Sign up</Link>
        <br/><br/>
        <Paragraph color="red">{validationMessage}</Paragraph>
        </form>
    </Pane>
  </Pane>
  );
}