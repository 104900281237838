import React, {useState} from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Paragraph, Dialog } from "evergreen-ui";

import { API } from "aws-amplify";

function CardDialog(props) {
    const [errorText, setErrorText] = useState(""); 
    const [isLoading, setIsLoading] = useState(false); 
    const [isCardComplete, setIsCardComplete] = useState(false);

    async function SubmitCard() {
        setIsLoading(true);
        setErrorText("");

        try {
            const cardElement = props.elements.getElement('card');
            props.stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
            })
            .then(async ({paymentMethod}) => {
                if (paymentMethod === undefined)
                {
                    setErrorText("Sorry. The payment method entered was invalid.")
                    setIsLoading(false);
                    return;
                }
                await AddPaymentMethod(paymentMethod.id, paymentMethod.card.last4);
                props.updateLast4(paymentMethod.card.last4);
                props.updateHasPaymentMethod(true);
                props.closeDialog()
            });
        } catch (e) {
            setErrorText(e.response.data.Message)
        }
    }

    function AddPaymentMethod(payment_method_id, l4) {
        return API.post("admin", "/account/payment-method", {
            body: {pm: payment_method_id, last4:l4}
        });
    }
    
    return (
            <Dialog
                isShown={props.showDialog}
                title="Add/invite user"
                onConfirm={() => {SubmitCard() } }
                onCloseComplete={() => {props.closeDialog(); setIsLoading(false); } }
                confirmLabel="Add/update payment card"
                isConfirmLoading={isLoading}
                isConfirmDisabled={!isCardComplete}
            >
                <Paragraph>Enter a credit card. This will be billed monthly.</Paragraph>
                <br/>
                <CardElement style={{base: {fontSize: '18px'}}} onChange={e => setIsCardComplete(e.complete)}/>
                <br/>
                <Paragraph color="red">{errorText}</Paragraph>
            </Dialog>
    );

}

export default injectStripe(CardDialog);