import React from "react";
import { Route, Switch } from "react-router-dom";
import { Home, NotFound, Login, Signup, TwilioConnect, Terms } from "./containers";
import { AppliedRoute,AuthenticatedRoute,UnauthenticatedRoute } from "./components";


export default function Routes({ appProps }) {
  return (
    <Switch>
      <AuthenticatedRoute path="/" exact component={Home} appProps={appProps} />
      <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
      <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps} />
      <AppliedRoute path="/terms" exact component={Terms} appProps={appProps} />
      <AuthenticatedRoute path="/twilioconnect" exact component={TwilioConnect} appProps={appProps} />

      { /* Finally, catch all unmatched routes */ }
      <Route component={NotFound} />
    </Switch>
  );
}
