import React, { useState,useEffect } from "react";
import { Pane, Heading, Table,Button,Paragraph,Spinner } from "evergreen-ui";
import { AddUserDialog,RemoveUserDialog } from "../components"
import { API } from "aws-amplify";

export default function UsersTable(props) {
    
    const [isLoading, setIsLoading] = useState(true);
    const [isDisabled, setIsDisabled] = useState(true);
    const [users, setUsers] = useState([]);
    const [addUserDialogShown, setAddUserDialogShown] = useState(false);
    const [removeUserDialogShown, setRemoveUserDialogShown] = useState(false);
    const [userToDelete, setUserToDelete] = useState("")
    const [errorText,setErrorText] = useState("");

    useEffect(()=> {
        async function rerender() {
            if (addUserDialogShown || removeUserDialogShown)
                return;
            setIsLoading(true)
            try {
                const userData = await getUsers();
                setUsers(userData);
                setIsDisabled(false);
            } catch (e) {
                console.log(JSON.stringify(e));
            }
            setIsLoading(false)
        }

        rerender();
    },[removeUserDialogShown,addUserDialogShown])

    useEffect(() => {
        async function onLoad() {
            setErrorText("");
            try {
                const userData = await getUsers();
                setUsers(userData);
                setIsDisabled(false);
            } catch (e) {
                setErrorText("Either your account is not yet provisioned, or a network error occurred");
            }
            setIsLoading(false)
        }
        
        onLoad();
    },[]);

    function getUsers () {
        return API.get("admin", "/reporting-users");
    };

    function showDeletePopup(username) {
        setUserToDelete(username);
        setRemoveUserDialogShown(true); 
    }

    function renderUserTable() {
        return (
            <>
                <Table>
                <Table.Head>
                    <Table.TextHeaderCell>Email (select row to delete)</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body height={240}>
                    {users.map(user => (
                    <Table.Row key={user.username} isSelectable onSelect={() => showDeletePopup(user.username) }>
                        <Table.TextCell>{user.email}</Table.TextCell>
                    </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
        );
    }

    return(
        <>
            <Pane>
                <Heading size={700}>Analytics Users</Heading><br/>
                <Paragraph>This is a list of all of the reporting users in the Envocate system. These users cannot log into the administration console. They can access the envocate reporting URL on the left hand pane. Use this pane to administer their access.</Paragraph>
            </Pane>
            <br/>
            <Pane>
                <Paragraph color="red">{errorText}</Paragraph>
                <Button disabled={isDisabled} onClick={() => {setAddUserDialogShown(true); } }>Invite</Button><br/><br/>
                { !isLoading ? renderUserTable() : <Spinner marginX="auto" marginY={120} />}
                <br/>
            </Pane>

        <AddUserDialog showDialog={addUserDialogShown} closeDialog={() => setAddUserDialogShown(false)} />
        <RemoveUserDialog showDialog={removeUserDialogShown} username={userToDelete} closeDialog={() => setRemoveUserDialogShown(false)} />

        </>
    )
}