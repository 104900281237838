import React, { useEffect} from "react";
import { Pane } from "evergreen-ui";

import { StatusBox,UsersTable } from "../components"

export default function Home(props) {

    useEffect(() => {
        async function onLoad() {
          if (!props.isAuthenticated) {
            return;
          }
      
          try {
            //const notes = await loadBillingData();
          } catch (e) {
            console.log(JSON.stringify(e));
          }
        }
      
        onLoad();
      }, [props.isAuthenticated]);

    return (
        <Pane marginTop={20}>
            <Pane width="50%" float="left">
              <StatusBox />
            </Pane>
            <Pane border="muted" padding={16} width="49%" float="left" marginLeft="1%">
              <UsersTable />
            </Pane>
        </Pane>
    );
}
