export default {
    s3: {
      REGION: "YOUR_S3_UPLOADS_BUCKET_REGION",
      BUCKET: "YOUR_S3_UPLOADS_BUCKET_NAME"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://yomc3w263e.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_ocuvP5aRT",
      APP_CLIENT_ID: "64reqvg16jilr0hen2v674197s",
      IDENTITY_POOL_ID: "us-east-1:c09e658d-e193-4302-885c-6c9bc2500f36"
    },
    STRIPE_KEY: "YOUR_STRIPE_PUBLIC_KEY",
  };
  